import React, {useCallback} from 'react';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {modalCreateJupytSlice} from 'features/Jupyter/store/slices/modals/modal-create-jupyt';
import {JupytListPageTopRow} from '../../components/JupytListPageTopRow/JupytListPageTopRow';
import {loadJupytPageList} from '../../store/actions/jupyt';
import {JupytCreateDialog} from '../Dialogs/JupytCreateDialog/JupytCreateDialog';

export const JupytListPageTopRowContainer = () => {
    const dispatch = useNebiusDispatch();

    const handleCreateClick = useCallback(() => {
        dispatch(modalCreateJupytSlice.actions.open());
    }, []);

    const handleJupytCreateDialogApply = React.useCallback(() => {
        return dispatch(loadJupytPageList());
    }, [dispatch]);

    return (
        <>
            <JupytListPageTopRow onCreateClick={handleCreateClick} />
            <JupytCreateDialog onApply={handleJupytCreateDialogApply} />
        </>
    );
};
