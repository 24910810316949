import React, {MutableRefObject} from 'react';
import cn from 'bem-cn-lite';
import key from 'hotkeys-js';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import 'monaco-editor/esm/vs/basic-languages/python/python.contribution';
import './JupyterMonacoEditor.scss';
import {RealTheme, withThemeValue} from '@gravity-ui/uikit';
import {
    NYT_JUPYTER_DARK_THEME,
    NYT_JUPYTER_LIGHT_THEME,
} from '../../lib/monaco-jupyter/themes/themes.contribution';

export const enum JupyterCellLanguage {
    Markdown = 'markdown',
    Python = 'python',
}

const IMAGE_MIME_TYPES = new Set([
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
]);

const block = cn('jupyter-monaco-editor');

const THEMES = {
    dark: NYT_JUPYTER_DARK_THEME,
    'dark-hc': 'hc-black',
    light: NYT_JUPYTER_LIGHT_THEME,
    'light-hc': 'hc-light',
};

const lineNumbersConfig = {
    lineNumbersMinChars: 4,
    lineDecorationsWidth: 5,
};

interface JupyterMonacoEditorComponentProps {
    className?: string;
    value: string;
    language?: JupyterCellLanguage;
    onChange: (value: string) => void;
    onBlur?: () => void;
    onImagePaste?: (value: {name: string; type: string; base64: string}) => void;
    editorRef?: MutableRefObject<monaco.editor.IStandaloneCodeEditor | undefined>;
    readOnly?: boolean;
    themeValue: RealTheme;
}

class JupyterMonacoEditorComponent extends React.Component<JupyterMonacoEditorComponentProps> {
    ref = React.createRef<HTMLDivElement>();
    model = monaco.editor.createModel('', this.props.language);
    editor?: monaco.editor.IStandaloneCodeEditor;
    onContentSizeChange?: monaco.IDisposable;
    prevScope?: string;
    silent = false;

    componentDidMount() {
        const {editorRef, readOnly, themeValue, language} = this.props;
        this.model.setValue(this.props.value);
        const container = this.ref.current!;
        const editor = (this.editor = monaco.editor.create(container, {
            model: this.model,
            renderLineHighlight: 'all',
            renderLineHighlightOnlyWhenFocus: true,
            colorDecorators: false,
            automaticLayout: true,
            scrollBeyondLastLine: false,
            fixedOverflowWidgets: true,
            readOnly,
            pasteAs: {
                enabled: false,
            },
            minimap: {
                enabled: false,
            },
            overviewRulerLanes: 0,
            overviewRulerBorder: false,
            wordBasedSuggestions: false,
            padding: {
                top: 10,
                bottom: 10,
            },
            theme: THEMES[themeValue as 'light' | 'light-hc' | 'dark' | 'dark-hc'],
            scrollbar: {
                alwaysConsumeMouseWheel: false,
            },
            fontSize: 14,
            renderWhitespace: 'boundary',
            wordWrap: language === JupyterCellLanguage.Markdown ? 'on' : 'off',
            ...lineNumbersConfig,
        }));
        this.model.onDidChangeContent(this.onContentChanged);
        this.editor.onDidBlurEditorText(this.onBlurEditorText);
        window.addEventListener('paste', this.onPaste);
        this.prevScope = key.getScope();
        key.setScope('monaco-editor');
        if (editorRef) {
            editorRef.current = this.editor;
        }

        this.onContentSizeChange = editor.onDidContentSizeChange(() => {
            const contentHeight = editor.getContentHeight();
            const contentWidth = container.clientWidth;
            editor.layout({width: contentWidth, height: contentHeight});
        });
    }

    componentDidUpdate(prevProps: Readonly<JupyterMonacoEditorComponentProps>): void {
        const {themeValue, value, readOnly, language} = this.props;
        const options: monaco.editor.IStandaloneEditorConstructionOptions = {};

        if (prevProps.themeValue !== themeValue) {
            options.theme = THEMES[themeValue as 'light' | 'light-hc' | 'dark' | 'dark-hc'];
        }

        if (value !== this.model.getValue()) {
            this.silent = true;
            this.model.setValue(value);
            this.silent = false;
        }
        if (language !== prevProps.language) {
            this.model = monaco.editor.createModel(this.model.getValue(), this.props.language);
            this.model.onDidChangeContent(this.onContentChanged); // the new model needs to re-specify the callback
            this.editor?.setModel(this.model);
        }
        if (readOnly !== prevProps.readOnly) {
            this.editor?.updateOptions({readOnly});
        }

        this.editor?.updateOptions(options);
    }

    componentWillUnmount() {
        this.editor?.getModel()?.dispose();
        this.editor?.dispose();
        this.onContentSizeChange?.dispose();
        window.removeEventListener('paste', this.onPaste);
        key.setScope(this.prevScope!);
    }

    render() {
        const {className} = this.props;

        return (
            <div className={block(null, className)}>
                <div ref={this.ref} className={block('editor')} />
            </div>
        );
    }

    onContentChanged = () => {
        if (this.silent) {
            return;
        }
        const {onChange} = this.props;
        const value = this.model.getValue();
        onChange(value);
    };

    private onBlurEditorText = () => {
        this.props.onBlur?.();
    };

    private onPaste = (clipboardEvent: ClipboardEvent) => {
        if (
            !this.editor?.hasTextFocus() ||
            !this.props.onImagePaste ||
            !clipboardEvent.clipboardData
        ) {
            return;
        }

        const file = Array.from(clipboardEvent.clipboardData.items)
            .find((item) => item.kind == 'file' && IMAGE_MIME_TYPES.has(item.type))
            ?.getAsFile();

        if (!file) {
            return;
        }

        const uuid = crypto.randomUUID();

        const attachmentName = `${uuid}`;

        this.editor.trigger('myapp', 'undo', {});
        this.editor.trigger('keyboard', 'type', {
            text: `![${file.name}](attachment:${attachmentName})`,
        });

        const fileReader = new FileReader();

        fileReader.onloadend = () => {
            this.props.onImagePaste?.({
                name: attachmentName,
                type: file.type,
                base64: String(fileReader.result).split(',')[1],
            });
        };

        fileReader.readAsDataURL(file);
    };
}

export const JupyterMonacoEditor = withThemeValue(JupyterMonacoEditorComponent);
