import React from 'react';

import {type DropdownMenuItem, Icon as UIKitIcon} from '@gravity-ui/uikit';
import {modalCreateNotebookSlice} from '../../../../features/Jupyter/store/slices/modals/modal-create-notebook';
import {modalCreateDocumentSlice} from '../../../../features/Navigation/store/slices/modals/modal-create-document';
import {getNebiusConfigData} from '../../../../config/ui-config';
import {isEnvTrue} from '../../../../../shared/utils/env';
import {getWindowStore} from '@ytsaurus-ui-platform/src/ui/store/window-store';
import {LogoPython} from '@gravity-ui/icons';
import Icon from '@ytsaurus-ui-platform/src/ui/components/Icon/Icon';

export const getNebiusMapNodeToolbarCreateActions = (
    actions: Array<DropdownMenuItem>,
): Array<DropdownMenuItem> => {
    const dispatch = getWindowStore().dispatch;

    const copy = [...actions];

    const createDocumentAction: DropdownMenuItem = {
        text: 'Document',
        iconStart: <Icon awesome="file-alt" />,
        action: () => dispatch(modalCreateDocumentSlice.actions.open()),
    };

    copy.splice(1, 0, createDocumentAction);

    if (!isEnvTrue(getNebiusConfigData().env.SHOW_JUPYT_INTERFACE)) {
        return copy;
    }

    const createNotebookAction: DropdownMenuItem = {
        action: () => dispatch(modalCreateNotebookSlice.actions.open()),
        text: 'Notebook',
        iconStart: <UIKitIcon data={LogoPython} size={13} />,
    };

    copy.splice(2, 0, createNotebookAction);

    return copy;
};
