import {ContentsManager, ServerConnection} from '@jupyterlab/services';
import type * as nbformat from '@jupyterlab/nbformat';
import {Toaster} from '@gravity-ui/uikit';
import {showErrorPopup, wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import {
    generateNotebookCypressId,
    getJupyterContentManagerPath,
} from 'features/Jupyter/utils/notebook';
import {SYSTEM_JUPYT_ALIAS} from '../../../../../shared/constants/jupyter/jupyter-api';
import {getJupyterBaseUrl} from '../../../../../shared/utils/jupyter/jupyter-api';

let contentsManagerSingleton: ContentsManager;

async function getContentsManager(cluster: string) {
    if (contentsManagerSingleton) {
        return contentsManagerSingleton;
    }

    const serverSettings = ServerConnection.makeSettings({
        baseUrl: getJupyterBaseUrl({cluster, alias: SYSTEM_JUPYT_ALIAS}),
    });

    contentsManagerSingleton = new ContentsManager({serverSettings: serverSettings});

    return contentsManagerSingleton;
}

type CreateNotebookPayload = {cluster: string; path: string};

export async function createNotebookInCypress({cluster, path}: CreateNotebookPayload) {
    const manager = await getContentsManager(cluster);

    let hasNode = true;

    try {
        await getNotebookContentFromCypress({cluster, path});
    } catch (error: any) {
        if ('response' in error && error.response.status === 404) {
            hasNode = false;
        }
    }

    const toaster = new Toaster();
    if (hasNode) {
        const errorMessage = `Node ${path} already exists`;
        toaster.add({
            theme: 'danger',
            name: 'NodeAlreadyExists',
            title: 'Notebook creation failure',
            content: errorMessage,
            autoHiding: 10000,
            actions: [
                {
                    label: ' view',
                    onClick: () => showErrorPopup({message: errorMessage}),
                },
            ],
        });

        return Promise.reject(errorMessage);
    }

    return wrapApiPromiseByToaster(
        manager.save(getJupyterContentManagerPath(path), {
            type: 'notebook',
            format: 'json',
            content: {
                nbformat: 4,
                nbformat_minor: 5,
                metadata: {
                    notebook_cypress_id: generateNotebookCypressId(),
                },
                cells: [],
            },
        }),
        {
            skipSuccessToast: true,
            toasterName: 'SaveNotebookError',
            errorTitle: 'Notebook creation failure',
            autoHide: true,
        },
    );
}

type SaveNotebookPayload = {cluster: string; path: string; content: nbformat.INotebookContent};

export async function saveNotebookInCypress({cluster, content, path}: SaveNotebookPayload) {
    const manager = await getContentsManager(cluster);

    await manager.save(getJupyterContentManagerPath(path), {
        content,
        format: 'json',
        type: 'notebook',
    });
}

type GetNotebookPayload = {
    cluster: string;
    path: string;
};

export async function getNotebookContentFromCypress({cluster, path}: GetNotebookPayload) {
    const manager = await getContentsManager(cluster);

    return manager.get(getJupyterContentManagerPath(path));
}

type GetDownloadUrlPayload = {
    cluster: string;
    path: string;
};

export async function getNotebookDownloadUrl({cluster, path}: GetDownloadUrlPayload) {
    const manager = await getContentsManager(cluster);
    return manager.getDownloadUrl(getJupyterContentManagerPath(path));
}
