import React from 'react';

import {useSelector} from 'react-redux';
import {selectModalCreateNotebookState} from '../../../store/selectors/modals';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog/Dialog';
import {createNotebook} from '../../../store/actions/notebook';
import {modalCreateNotebookSlice} from '../../../store/slices/modals/modal-create-notebook';
import {useNebiusDispatch} from '../../../../../store/nebius-dispatch';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {updateView} from '@ytsaurus-ui-platform/src/ui/store/actions/navigation';

type FormValues = {notebookPath: string};

export const NotebookSaveDialog = () => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalCreateNotebookState);
    const path = useSelector(getPath);

    const handleIsApplyDisabled = () => {
        return false;
    };

    return (
        <YTDFDialog<FormValues>
            visible={state.visible}
            headerProps={{title: 'Create notebook'}}
            onAdd={(args) => {
                return dispatch(createNotebook(args.getState().values.notebookPath))
                    .then(({payload}) =>
                        payload
                            ? dispatch(modalCreateNotebookSlice.actions.close())
                            : Promise.reject(),
                    )
                    .then(() => dispatch(updateView()));
            }}
            onClose={() => {
                dispatch(modalCreateNotebookSlice.actions.close());
            }}
            fields={[
                {
                    name: 'notebookPath',
                    type: 'text',
                    caption: 'Notebook path',
                    tooltip: 'Path to save notebook',
                    initialValue: `${path}/New notebook`,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
