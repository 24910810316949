import * as React from 'react';
import {Button} from '@gravity-ui/uikit';
import cn from 'bem-cn-lite';

const block = cn('jupyt-list-page-top-row');

import './JupytListPageTopRow.scss';

export const JupytListPageTopRow: React.FC<{
    onCreateClick: () => void;
}> = (props) => {
    return (
        <>
            <div className={block()}>
                <Button view="action" onClick={props.onCreateClick}>
                    Create kernel
                </Button>
            </div>
        </>
    );
};
