import type {Output} from '@diplodoc/transform';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import axios from 'axios';
import type {MarkdownRequestBody} from '../../shared/types/common/markdown';

export async function nebiusTransformMarkdown(body: MarkdownRequestBody): Promise<Output> {
    try {
        const {data} = await wrapApiPromiseByToaster(
            axios.post<Output>('/api/nebius-markdown-to-html', body, {
                headers: {'Content-Type': 'text/plain'},
            }),
            {
                toasterName: 'api.transformMarkdown',
                skipSuccessToast: true,
                errorContent: 'Failed to transform markdown text',
            },
        );
        return data;
    } catch (e) {
        return {
            result: {html: '', headings: []},
            logs: {info: [], warn: [], disabled: [], error: [(e as Error).message]},
        };
    }
}
