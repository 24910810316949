import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import {selectCurrentJupytAlias} from '../../../store/selectors/notebook';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {JupyterApi} from '../../../api/kernel';
import {prepareJupyterSuggestions} from '../../../components/JupyterMonacoEditor/helpers/suggestions';

export const setupMonacoAutocompleteExtension = () => {
    monaco.languages.registerCompletionItemProvider('python', {
        triggerCharacters: ['.'],
        async provideCompletionItems(model, position) {
            const code = model.getValue();
            const cursor = model.getOffsetAt(position);

            const state = window.store.getState();

            const alias = selectCurrentJupytAlias(state);
            const cluster = getCluster(state);

            const word = model.getWordUntilPosition(position);
            const range: monaco.IRange = {
                startLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endLineNumber: position.lineNumber,
                endColumn: word.endColumn,
            };

            const result = await JupyterApi.getCompletion(code, cursor, {alias, cluster});
            if (result.content.status === 'ok') {
                const content = result.content;
                const matches = content.matches;
                const experimentMatches: any = content.metadata
                    ? content.metadata._jupyter_types_experimental ?? []
                    : [];
                return Promise.resolve({
                    suggestions: prepareJupyterSuggestions(matches, range, experimentMatches),
                });
            }

            return Promise.resolve({suggestions: []});
        },
    });
};
