import React from 'react';
import block from 'bem-cn-lite';
import {Text} from '@gravity-ui/uikit';
import './JupyterCellPlaceholder.scss';
import {NotebookPageQa} from '../../../../../shared/qa';

const b = block('jupyter-cell-placeholder');

type JupyterCellPlaceholderProps = {
    onClick: () => void;
    visible?: boolean;
};

export const JupyterCellPlaceholder = ({visible, onClick}: JupyterCellPlaceholderProps) => {
    return (
        <div
            data-qa={NotebookPageQa.PlaceholderAddCellButton}
            className={b({visible})}
            onClick={onClick}
        >
            <Text variant="body-2">Click to add a cell</Text>
        </div>
    );
};
