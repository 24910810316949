import React from 'react';

import {CircleFill} from '@gravity-ui/icons';
import {Icon} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import type {JupytServerStateType} from '../../../api/jupyt';

import './JupytStateIcon.scss';

const b = block('jupyt-state-icon');

type JupytStateIconProps = {
    state: JupytServerStateType;
};

export const JupytStateIcon: React.FC<JupytStateIconProps> = (props: JupytStateIconProps) => {
    return <Icon size="18" data={CircleFill} className={b({[props.state]: true})} />;
};
