import React from 'react';
import block from 'bem-cn-lite';

import '@diplodoc/transform/dist/css/yfm.css';
import '@diplodoc/transform/dist/js/yfm';

type NebiusMarkdownProps = {
    html: string;
    className?: string;
};

const b = block('nebius-markdown');

const NebiusMarkdownComponent: React.FC<NebiusMarkdownProps> = (props: NebiusMarkdownProps) => {
    const {className, html} = props;
    const mixins: string[] = React.useMemo(() => {
        return className ? [className, 'yfm'] : ['yfm'];
    }, [className]);

    return <div className={b(null, mixins)} dangerouslySetInnerHTML={{__html: html}} />;
};

export const NebiusMarkdown = React.memo(NebiusMarkdownComponent);
