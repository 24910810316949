import React from 'react';
import {ChevronDown, ChevronUp} from '@gravity-ui/icons';
import {Button, Flex, Icon, Select, type SelectOption, Text} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';

import {
    JupytListResponseItem,
    type JupytServerHealthType,
    type JupytServerStateType,
} from '../../api/jupyt';

import {NebiusYT} from '../../../../config/ui-config';
import {JupytSelectOption} from './JupytSelectOption/JupytSelectOption';
import type {JupytSelectOptionData} from './types';
import {NotebookPageQa} from '../../../../../shared/qa';

import './JupytSelect.scss';

const selectifyJupytItem = (item: JupytListResponseItem): SelectOption<JupytSelectOptionData> => ({
    content: item.$value,
    value: item.$value,
    data: item.$attributes,
});

type JupytSelectorProps = {
    jupyts: JupytListResponseItem[];
    onUpdate: (value: string[]) => void;
    onEmptyClick: () => void;
    currentJupyt: [string] | undefined;
};

const b = block('jupyt-select');

const getDefaultHealth = (health: JupytServerHealthType | undefined) => health ?? 'failed';
const getDefaultState = (state: JupytServerStateType | undefined) => state ?? 'untracked';

export const JupytSelect: React.FC<JupytSelectorProps> = (props: JupytSelectorProps) => {
    const {jupyts, currentJupyt, onUpdate, onEmptyClick} = props;

    const selectorItems: SelectOption<JupytSelectOptionData>[] = React.useMemo(() => {
        const selectors = jupyts.map(selectifyJupytItem);

        if (NebiusYT.devJupytConfig) {
            selectors.push(
                ...Object.keys(NebiusYT.devJupytConfig ?? {}).map((item) => ({
                    value: item,
                    content: item,
                    data: {dev: true},
                })),
            );
        }

        return selectors;
    }, [jupyts]);

    const isEmpty = selectorItems.length === 0;

    const currentJupytItem = jupyts.find((jupyt) => jupyt.$value === currentJupyt?.[0]);

    return (
        <Select
            popupClassName={b('popup')}
            value={currentJupyt}
            onUpdate={onUpdate}
            multiple={false}
            options={selectorItems}
            filterable={true}
            renderOption={(option) => (
                <JupytSelectOption
                    value={option.value}
                    state={getDefaultState(option.data?.state)}
                    health={getDefaultHealth(option.data?.health)}
                />
            )}
            renderControl={(props, options) => (
                <Button
                    {...props}
                    qa={NotebookPageQa.KernelSelect}
                    onClick={isEmpty ? onEmptyClick : props.onClick}
                    ref={props.ref}
                    className={b('control')}
                    view="outlined"
                >
                    <Flex alignItems="center">
                        {currentJupytItem ? (
                            <JupytSelectOption
                                value={options.value![0]}
                                state={getDefaultState(currentJupytItem.$attributes?.state)}
                                health={getDefaultHealth(currentJupytItem.$attributes?.health)}
                            />
                        ) : (
                            <Text variant="body-1">
                                {isEmpty ? 'Create kernel' : 'Select kernel'}
                            </Text>
                        )}
                        {isEmpty ? null : (
                            <Icon
                                className={b('icon')}
                                data={props.open ? ChevronUp : ChevronDown}
                            />
                        )}
                    </Flex>
                </Button>
            )}
        />
    );
};
