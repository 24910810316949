import React, {useCallback} from 'react';

import {useNebiusDispatch} from 'store/nebius-dispatch';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import {JupyterCellPlaceholder} from 'features/Jupyter/components/JupyterCellPlaceholder/JupyterCellPlaceholder';

type AddJupyterCellConainerProps = {addIndex: number; visible?: boolean};

export const JupyterCellPlaceholderContainer = ({
    addIndex,
    visible,
}: AddJupyterCellConainerProps) => {
    const dispatch = useNebiusDispatch();

    const onClick = useCallback(() => {
        dispatch(notebookSlice.actions.addCellAfter({currentIndex: addIndex, type: 'code'}));
    }, [addIndex]);

    return <JupyterCellPlaceholder onClick={onClick} visible={visible} />;
};
