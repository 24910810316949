import React from 'react';
import {Disclosure} from '@gravity-ui/uikit';
import block from 'bem-cn-lite';
import './MonitoringStubComponent.scss';

const b = block('monitoring-stub-component');

type MonitoringStubComponentProps = {
    pluginPoint: string;
    restProps: any;
};

export const MonitoringStubComponent = (props: MonitoringStubComponentProps) => {
    const {pluginPoint, restProps} = props;

    const summary = `This is a stub component for plugin point "${pluginPoint}". Here is the data which we have here be default (click me):`;

    return (
        <div className={b()}>
            <Disclosure size="l" summary={summary}>
                {JSON.stringify(restProps, null, 2)}
            </Disclosure>
        </div>
    );
};
