import React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router';

import Tabs from '@ytsaurus-ui-platform/src/ui/components/Tabs/Tabs';
import {makeTabProps} from '@ytsaurus-ui-platform/src/ui/utils';
import cn from 'bem-cn-lite';

import './JupytPageOperationTabs.scss';

interface JupytPageOperationTabsProps {
    renderACLTab: () => React.ReactNode;
    renderSpecletTab: () => React.ReactNode;
    renderSecretsTab: () => React.ReactNode;
    className?: string;
}

const TABS = {
    SPECLET: 'speclet',
    SECRETS: 'secrets',
    ACL: 'Acl',
};

const block = cn('jupyt-page-operations-tabs');

export function JupytPageOperationTabs(props: JupytPageOperationTabsProps) {
    const match = useRouteMatch();

    const tabProps = React.useMemo(() => {
        return makeTabProps(match.url, TABS);
    }, [match.url]);

    return (
        <React.Fragment>
            <Tabs className={block()} {...tabProps} routed />
            <Switch>
                <Route path={`${match.url}/${TABS.SPECLET}`} component={props.renderSpecletTab} />
                <Route path={`${match.url}/${TABS.ACL}`} component={props.renderACLTab} />
                <Route path={`${match.url}/${TABS.SECRETS}`} component={props.renderSecretsTab} />
                <Redirect to={`${match.url}/${TABS.SPECLET}`} />
            </Switch>
        </React.Fragment>
    );
}
