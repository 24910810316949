import React from 'react';

import {useSelector} from 'react-redux';
import {selectModalSaveAsNotebookState} from '../../../store/selectors/modals';
import {YTDFDialog} from '@ytsaurus-ui-platform/src/ui/components/Dialog/Dialog';
import {saveNotebookAs} from '../../../store/actions/notebook';
import {modalSaveAsNotebookSlice} from '../../../store/slices/modals/modal-copy-notebook';
import {useNebiusDispatch} from '../../../../../store/nebius-dispatch';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import {useHistory} from 'react-router';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global/index';
import {selectNotebookContent} from 'features/Jupyter/store/selectors/notebook';

type FormValues = {notebookPath: string};

export const NotebookSaveAsDialog = () => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalSaveAsNotebookState);
    const path = useSelector(getPath);
    const cluster = useSelector(getCluster);
    const history = useHistory();
    const notebookContent = useSelector(selectNotebookContent)!;

    const handleIsApplyDisabled = () => {
        return false;
    };

    return (
        <YTDFDialog<FormValues>
            visible={state.visible}
            headerProps={{title: 'Save notebook as'}}
            onAdd={(args) => {
                const {notebookPath} = args.getState().values;

                return dispatch(saveNotebookAs({notebookPath, notebookContent}))
                    .then(({payload}) =>
                        payload
                            ? dispatch(modalSaveAsNotebookSlice.actions.close())
                            : Promise.reject(),
                    )
                    .then(() => {
                        history.push(`/${cluster}/navigation?path=${notebookPath}`);
                    });
            }}
            onClose={() => {
                dispatch(modalSaveAsNotebookSlice.actions.close());
            }}
            fields={[
                {
                    name: 'notebookPath',
                    type: 'text',
                    caption: 'New notebook path',
                    tooltip: 'Path to new notebook',
                    initialValue: `${path} copy`,
                },
            ]}
            isApplyDisabled={handleIsApplyDisabled}
        />
    );
};
