import {createSlice} from '@reduxjs/toolkit';

type ModalCreateNotebookInitialState = {
    visible: boolean;
};

const initialState: ModalCreateNotebookInitialState = {
    visible: false,
};

export const modalCreateNotebookSlice = createSlice({
    name: 'jupyter.modal.create-notebook',
    initialState,
    reducers: {
        open: (state) => {
            state.visible = true;
        },
        close: (state) => {
            state.visible = false;
        },
    },
});
