import type {NebiusRootState} from '../../../../store/reducers';

export const selectModalCreateNotebookState = (state: NebiusRootState) =>
    state.nebius.jupyter.modals.modalCreateNotebook;

export const selectModalCreateJupytState = (state: NebiusRootState) =>
    state.nebius.jupyter.modals.modalCreateJupyt;

export const selectModalSaveAsNotebookState = (state: NebiusRootState) =>
    state.nebius.jupyter.modals.modalSaveAsNotebook;
