import type {
    RequestContext,
    SDK,
    VictoriaMetricsRangeRequest,
    VictoriaMetricsRangeResponse,
    VictoriaMetricsSeriesRequest,
    VictoriaMetricsSeriesResponse,
} from '@nebius-ui/spd';
import axios from 'axios';
import YT from '@ytsaurus-ui-platform/src/ui/config/yt-config';

export const monitoringSdk: SDK = {
    requestSeries: async (
        request: VictoriaMetricsSeriesRequest,
        _context: RequestContext,
    ): Promise<VictoriaMetricsSeriesResponse> => {
        const cluster = YT.cluster;

        const response = await axios.post<VictoriaMetricsSeriesResponse>(
            `/${cluster}/monitoring/select/multitenant/prometheus/api/v1/series`,
            request,
        );

        return response.data;
    },

    requestRange: async (
        request: VictoriaMetricsRangeRequest,
        _context: RequestContext,
    ): Promise<VictoriaMetricsRangeResponse> => {
        const cluster = YT.cluster;
        const response = await axios.post<VictoriaMetricsRangeResponse>(
            `/${cluster}/monitoring/select/multitenant/prometheus/api/v1/query_range`,
            request,
        );

        return response.data;
    },
};
