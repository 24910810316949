import {JupytListTableItem} from '../../api/jupyt';

export const JUPYTER_OPERATIONS_TABLE_TITLES: Record<
    'speclet_modification_time' | 'strawberry_state_modification_time',
    string
> &
    Partial<Record<keyof JupytListTableItem, string>> = {
    health: 'Health',
    pool: 'Pool',
    state: 'State',
    alias: 'Kernel alias',
    cpu: 'Cores',
    memory: 'Memory',
    creator: 'Creator',
    docker_image: 'Docker image',
    speclet_modification_time: 'Speclet changed',
    strawberry_state_modification_time: 'Handled by ctl',
    yt_operation_finish_time: 'Finish time',
    yt_operation_start_time: 'Start time',
    creation_time: 'Creation time',
};
