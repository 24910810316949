import type {NebiusRootState} from '../../../../store/reducers';
import {createSelector} from 'reselect';
import {RootState} from '@ytsaurus-ui-platform/src/ui/store/reducers';
import {getSettingsData} from '@ytsaurus-ui-platform/src/ui/store/selectors/settings-base';

export const getJupytListColumnsFromSettings = (state: RootState) => {
    return (
        getSettingsData(state)['global::jupyt::list_columns'] ??
        (['health', 'state', 'cpu', 'memory', 'creator', 'creation_time'] as const)
    );
};

export const getJupytListVisibleColumns = createSelector(
    [getJupytListColumnsFromSettings],
    (columns) => {
        return columns;
    },
);

export const selectJupytOperations = (state: NebiusRootState) =>
    state.nebius.jupyter.jupyt.operations;

export const getJupytOperations = createSelector(selectJupytOperations, (operations) => {
    return operations.map((op) => ({
        alias: op.$value,
        ...op.$attributes,
    }));
});

export const selectJupytDefaults = (state: NebiusRootState) =>
    state.nebius.jupyter.jupyt.jupytDefaults;
