import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const NYT_JUPYTER_LIGHT_THEME = 'nyt-jupyter-light';

monaco.editor.defineTheme(NYT_JUPYTER_LIGHT_THEME, {
    base: 'vs',
    inherit: true,
    rules: [],
    colors: {
        'editor.background': '#f0f0f0',
        'editor.lineHighlightBackground': '#00000015',
        'editor.lineHighlightBorder': '#00000000',
        'editorLineNumber.foreground': '#23789398',
    },
});

export const NYT_JUPYTER_DARK_THEME = 'nyt-jupyter-dark';

monaco.editor.defineTheme(NYT_JUPYTER_DARK_THEME, {
    base: 'vs-dark',
    inherit: true,
    rules: [],
    colors: {
        'editor.background': '#181818',
        'editor.lineHighlightBackground': '#FFFFFF15',
        'editor.lineHighlightBorder': '#00000000',
        'editorLineNumber.foreground': '#85858598',
    },
});
