import {selectNotebookContent} from '../selectors/notebook';
import {createNebiusAsyncThunk} from '../../../../store/nebius-async-thunk';
import {notebookSlice} from '../slices/notebook';
import {getItemStrict} from '../../utils/strict-selectors';
import {mapNotebookToLatestVersion} from '../../mappers/notebook';
import {
    createNotebookInCypress,
    getNotebookContentFromCypress,
    saveNotebookInCypress,
} from '../../api/kernel/content-manager';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import throttle from 'lodash/throttle';
import {getPath} from '@ytsaurus-ui-platform/src/ui/store/selectors/navigation';
import type * as nbformat from '@jupyterlab/nbformat';

const SAVE_NOTEBOOK_CONTENT = 'jupyter/SAVE_NOTEBOOK_CONTENT';
const SAVE_NOTEBOOK_CONTENT_THROTTLE = 'jupyter/SAVE_NOTEBOOK_CONTENT_THROTTLE';
const SAVE_AS_NOTEBOOK_CONTENT = 'jupyter/SAVE_AS_NOTEBOOK_CONTENT';
const GET_NOTEBOOK_CONTENT = 'jupyter/GET_NOTEBOOK_CONTENT';
const CLEAR_NOTEBOOK_STATE = 'jupyter/CLEAR_NOTEBOOK_STATE';

export const saveNotebookContent = createNebiusAsyncThunk<void>(
    SAVE_NOTEBOOK_CONTENT,
    async (_, thunkApi) => {
        const notebook = getItemStrict(selectNotebookContent(thunkApi.getState()));

        const cluster = getCluster(thunkApi.getState());

        const path: string = getPath(thunkApi.getState());

        await saveNotebookInCypress({
            cluster,
            content: notebook,
            path,
        });

        thunkApi.dispatch(notebookSlice.actions.updateSavedNotebookContent());
    },
);

const _saveNotebookContentThrottle = throttle(async ({content}, thunkApi) => {
    const state = thunkApi.getState();
    const cluster = getCluster(state);
    const path: string = getPath(state);

    await saveNotebookInCypress({
        cluster,
        content,
        path,
    });

    thunkApi.dispatch(notebookSlice.actions.updateSavedNotebookContent());
}, 5000);

export const saveNotebookContentThrottle = createNebiusAsyncThunk<
    void,
    {content: nbformat.INotebookContent}
>(SAVE_NOTEBOOK_CONTENT_THROTTLE, _saveNotebookContentThrottle);

export const createNotebook = createNebiusAsyncThunk<boolean, string>(
    SAVE_AS_NOTEBOOK_CONTENT,
    async (notebookPath, thunkApi) => {
        const cluster = getCluster(thunkApi.getState());

        try {
            await createNotebookInCypress({
                path: notebookPath,
                cluster,
            });
            return true;
        } catch {
            return thunkApi.rejectWithValue(false);
        }
    },
);

export const saveNotebookAs = createNebiusAsyncThunk<
    boolean,
    {notebookPath: string; notebookContent: nbformat.INotebookContent}
>(SAVE_AS_NOTEBOOK_CONTENT, async ({notebookPath, notebookContent}, thunkApi) => {
    const cluster = getCluster(thunkApi.getState());

    try {
        await createNotebookInCypress({
            path: notebookPath,
            cluster,
        });
        await saveNotebookInCypress({
            cluster,
            content: notebookContent,
            path: notebookPath,
        });
        return true;
    } catch {
        return thunkApi.rejectWithValue(false);
    }
});

export const loadNotebookContent = createNebiusAsyncThunk<void, string>(
    GET_NOTEBOOK_CONTENT,
    async (path: string, thunkApi) => {
        const cluster = getCluster(thunkApi.getState());

        const model = await getNotebookContentFromCypress({
            cluster,
            path,
        });

        thunkApi.dispatch(
            notebookSlice.actions.setNotebook({
                notebook: mapNotebookToLatestVersion(model.content),
            }),
        );
    },
);

export const setNotebookContent = createNebiusAsyncThunk<void, nbformat.INotebookContent>(
    'jupyter/SET_NOTEBOOK_CONENT',
    async (notebook: nbformat.INotebookContent, thunkApi) => {
        thunkApi.dispatch(
            notebookSlice.actions.setNotebook({
                notebook: notebook,
            }),
        );
    },
);

export const clearNotebookState = createNebiusAsyncThunk<void, void>(
    CLEAR_NOTEBOOK_STATE,
    (_, thunkApi) => {
        thunkApi.dispatch(notebookSlice.actions.clearNotebookState());
    },
);
