import {combineSlices} from '@reduxjs/toolkit';
import {notebookSlice} from './notebook';
import {jupytSlice} from './jupyt';
import {jupytOperationSlice} from './jupytOperation';
import {modals} from './modals';

export const jupyterRoot = combineSlices({
    jupyt: jupytSlice.reducer,
    notebook: notebookSlice.reducer,
    jupytOperation: jupytOperationSlice.reducer,
    modals: modals,
});
