/* eslint-disable new-cap */

import * as React from 'react';
import cn from 'bem-cn-lite';
import MetaTable, {
    MetaTableItem,
} from '@ytsaurus-ui-platform/src/ui/components/MetaTable/MetaTable';
import {Loader, Text} from '@gravity-ui/uikit';
import moment from 'moment/moment';
import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import Label from '@ytsaurus-ui-platform/src/ui/components/Label/Label';
import {CHYT_TABLE_TITLES} from '@ytsaurus-ui-platform/src/ui/constants/chyt-page';
import {CliqueState} from '@ytsaurus-ui-platform/src/ui/pages/chyt/components/CliqueState';
import {SubjectCard} from '@ytsaurus-ui-platform/src/ui/components/SubjectLink/SubjectLink';
import {OperationId} from '@ytsaurus-ui-platform/src/ui/components/OperationId/OperationId';
import {OperationPool} from '@ytsaurus-ui-platform/src/ui/components/OperationPool/OperationPool';

import {JupytStatusResponse} from '../../api/jupyt';
import {JupytListTableActions} from '../JupytList/JupytListTable/JupytListTableActions/JupytListTableActions';

import './JupytOperationPage.scss';

const block = cn('jupyt-operation-page');

interface JupytOperationPageProps {
    alias: string;
    cluster: string;
    operation: JupytStatusResponse;
    onRemoveClick: () => void;
    onStartClick: () => void;
    onStopClick: () => void;
    tabs: React.ReactNode;
}

export const JupytOperationPageLoader = () => {
    return (
        <div className={block('loader')}>
            <Loader size="s" />
        </div>
    );
};

export const JupytOperationPage: React.FC<JupytOperationPageProps> = (props) => {
    return (
        <div className={block()}>
            <div className={block('header')}>
                <Text variant="header-1">
                    Kernel{' '}
                    <Text variant="header-1" color="secondary">
                        {props.alias}
                    </Text>
                </Text>
                <span className={block('spacer')} />
                <JupytListTableActions
                    onStopClick={props.onStopClick}
                    onStartClick={props.onStartClick}
                    onRemoveClick={props.onRemoveClick}
                />
            </div>
            <JupytOperationMetaTable data={props.operation} cluster={props.cluster} />
            {props.tabs ? <div className={block('tabs')}>{props.tabs}</div> : null}
        </div>
    );
};

function JupytOperationMetaTable({data, cluster}: {data: JupytStatusResponse; cluster: string}) {
    const items: Array<Array<MetaTableItem>> = React.useMemo(() => {
        const {
            pool,
            state,
            stage,
            ctl_attributes,
            yt_operation,
            health,
            incarnation_index,
            creator,
            speclet_modification_time,
            strawberry_state_modification_time,
        } = data ?? {};

        const {start_time, finish_time, id} = yt_operation ?? {};

        const start_time_number = start_time ? moment(start_time).valueOf() : undefined;
        const finish_time_number = finish_time
            ? moment(finish_time).valueOf()
            : start_time_number
              ? Date.now()
              : undefined;

        const duration =
            !start_time_number || !finish_time_number
                ? undefined
                : finish_time_number - start_time_number;

        return [
            [
                {key: 'Health', value: <CliqueState state={health} />},
                {key: 'State', value: <CliqueState state={state} />},
                {
                    key: 'Pool',
                    value: pool ? (
                        <OperationPool cluster={cluster} pool={{pool, tree: 'physical'}} />
                    ) : (
                        format.NO_VALUE
                    ),
                },
                {key: 'Cores', value: format.Number(ctl_attributes?.cpu)},
                {key: 'Memory', value: format.Bytes(ctl_attributes?.memory)},
            ],
            [
                {key: 'Stage', value: stage ? <Label capitalize text={stage} /> : format.NO_VALUE},
                {key: 'Incarnation index', value: format.Number(incarnation_index)},
                {
                    key: 'Creator',
                    value: creator ? <SubjectCard name={creator} /> : format.NO_VALUE,
                },
                {
                    key: CHYT_TABLE_TITLES['speclet_modification_time'],
                    value: format.DateTime(speclet_modification_time),
                },
                {
                    key: CHYT_TABLE_TITLES['strawberry_state_modification_time'],
                    value: format.DateTime(strawberry_state_modification_time),
                },
            ],
            [
                {
                    key: 'YT operation id',
                    value: (
                        <div className={block('operation-id')}>
                            <OperationId id={id} cluster={cluster} />
                        </div>
                    ),
                },
                {
                    key: 'YT operation state',
                    value: yt_operation?.state
                        ? format.ReadableField(yt_operation?.state)
                        : format.NO_VALUE,
                },
                {
                    key: 'Start time',
                    value: format.DateTime(start_time),
                },
                {
                    key: 'Finish time',
                    value: format.DateTime(finish_time),
                },
                {
                    key: 'Duration',
                    value: duration ? format.TimeDuration(duration) : format.NO_VALUE,
                },
            ],
        ];
    }, [data, cluster]);

    return <MetaTable items={items} />;
}
