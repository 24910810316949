import React from 'react';

import {useSelector} from 'react-redux';
import {selectModalCreateJupytState} from '../../../store/selectors/modals';
import {YTDFDialog, makeErrorFields} from '@ytsaurus-ui-platform/src/ui/components/Dialog/Dialog';
import {modalCreateJupytSlice} from '../../../store/slices/modals/modal-create-jupyt';
import {Text} from '@gravity-ui/uikit';
import type {YTError} from '@ytsaurus-ui-platform/src/@types/types';
import {WaitForDefaultPoolTree} from '@ytsaurus-ui-platform/src/ui/hooks/global-pool-trees';
import {createJupyt} from '../../../store/actions/jupyt';
import {useNebiusDispatch} from '../../../../../store/nebius-dispatch';
import {registerDialogControl} from '@gravity-ui/dialog-fields';
import {JupytSecrets} from '../../JupytSecretsContainer/JupytSecretsContainer';
import {mapListToJupytSecrets} from '../../../../../../shared/utils/jupyt/secrets';
import {JupytSecretType} from '../../../../../../shared/types/jupyt/secrets';
import {selectJupytDefaults} from 'features/Jupyter/store/selectors/jupyt';

registerDialogControl('jupyter-secrets', JupytSecrets as any);

type FormValues = {
    alias: string;
    tree: string;
    pool: string;
    dockerImage: string;
    runAfterCreation: boolean;
    secrets: JupytSecretType[];
};

type JupytCreateDialogProps = {
    onApply?: () => void;
    onAfterJupytCreate?: (alias: string) => Promise<void> | void;
};

const DEFAULT_JUPYT_POOL = 'research';

export const JupytCreateDialog: React.FC<JupytCreateDialogProps> = (
    props: JupytCreateDialogProps,
) => {
    const dispatch = useNebiusDispatch();
    const state = useSelector(selectModalCreateJupytState);
    const jupytDefaults = useSelector(selectJupytDefaults);

    const [error, _setError] = React.useState<YTError | undefined>();

    const handleAdd = React.useCallback((formValues: FormValues) => {
        const secrets = mapListToJupytSecrets(formValues.secrets);

        return dispatch(
            createJupyt({
                alias: formValues.alias,
                speclet_options: {
                    pool: formValues.pool,
                    active: formValues.runAfterCreation,
                    jupyter_docker_image: formValues.dockerImage.trim(),
                },
                secrets,
            }),
        )
            .then(() => props.onAfterJupytCreate?.(formValues.alias))
            .then(() => dispatch(modalCreateJupytSlice.actions.close()))
            .then(() => props.onApply?.());
    }, []);

    return (
        <WaitForDefaultPoolTree>
            {({defaultPoolTree}) => (
                <YTDFDialog<FormValues>
                    visible={state.visible}
                    headerProps={{title: 'Create kernel'}}
                    onAdd={(args) => {
                        return handleAdd(args.getState().values);
                    }}
                    onClose={() => {
                        dispatch(modalCreateJupytSlice.actions.cancel());
                    }}
                    fields={[
                        {
                            name: 'alias',
                            type: 'text',
                            caption: 'Alias name',
                            required: true,
                        },
                        {
                            name: 'dockerImage',
                            type: 'text',
                            caption: 'Docker image',
                            required: true,
                        },
                        {
                            name: 'tree',
                            type: 'pool-tree',
                            caption: 'Pool tree',
                            extras: {
                                disabled: true,
                            },
                        },
                        {
                            name: 'pool',
                            type: 'pool',
                            caption: 'Pool',
                            required: true,
                            extras: ({tree}: FormValues) => {
                                return {
                                    poolTree: tree,
                                    placeholder: 'Pool name...',
                                };
                            },
                        },
                        {
                            name: 'secrets',
                            type: 'jupyter-secrets',
                            caption: 'Secrets',
                        } as any,
                        {
                            name: 'poolNotice',
                            type: 'block',
                            visibilityCondition: {
                                when: 'pool',
                                isActive(pool) {
                                    return !pool;
                                },
                            },
                            extras: {
                                children: (
                                    <Text color="info-heavy" variant="body-2">
                                        Select a pool to start the jupyt after creation.
                                    </Text>
                                ),
                            },
                        },
                        {
                            name: 'runAfterCreation',
                            type: 'tumbler',
                            caption: 'Start after creation',
                            visibilityCondition: {
                                when: 'pool',
                                isActive(v) {
                                    return Boolean(v);
                                },
                            },
                        },
                        ...makeErrorFields([error]),
                    ]}
                    initialValues={{
                        tree: defaultPoolTree,
                        runAfterCreation: true,
                        dockerImage: jupytDefaults.default_kernel_image,
                        pool: DEFAULT_JUPYT_POOL,
                        secrets: [],
                    }}
                />
            )}
        </WaitForDefaultPoolTree>
    );
};
