import React from 'react';

import {
    DefaultSubjectCard,
    type SubjectCardProps,
} from '@ytsaurus-ui-platform/src/ui/components/SubjectLink/SubjectLink';
import {type GetUserData, UserCardPopover, type UserData} from '@nebius-ui/ava';
import {getSysUsersAzureAttributeData} from '../../yt-api/v4/users';
import {getAzureUserEmail, getAzureUserName} from '../../utils/user';

export type NebiusSubjectCardProps = SubjectCardProps;

export const NebiusSubjectCard: React.FC<NebiusSubjectCardProps> = (props) => {
    const getUserData: GetUserData = async (): Promise<UserData> => {
        const login = String(props.name);
        try {
            const response = await getSysUsersAzureAttributeData(login);
            return {
                email: getAzureUserEmail(response.value),
                name: getAzureUserName(response.value, login),
            };
        } catch {
            return {
                email: '',
                name: login,
            };
        }
    };

    return (
        <UserCardPopover getUserData={getUserData}>
            <DefaultSubjectCard {...props} />
        </UserCardPopover>
    );
};
