import {createNebiusAsyncThunk} from '../../../../../store/nebius-async-thunk';
import {modalCreateJupytSlice} from '../../slices/modals/modal-create-jupyt';
import {selectModalCreateJupytState} from '../../selectors/modals';

const SHOW_ASYNC_CREATE_JUPYT_MODAL = 'SHOW_ASYNC_CREATE_JUPYT_MODAL';

export const asyncOpenCreateJupytDialog = createNebiusAsyncThunk(
    SHOW_ASYNC_CREATE_JUPYT_MODAL,
    (_, thunkAPI) => {
        thunkAPI.dispatch(modalCreateJupytSlice.actions.open());
        const store = window.store;

        return new Promise<boolean>((resolve) => {
            const unsubscribe = store.subscribe(() => {
                const state = store.getState();
                const modalState = selectModalCreateJupytState(state);
                if (!modalState.visible) {
                    unsubscribe();
                    resolve(!modalState.isCanceled);
                }
            });
        });
    },
);
