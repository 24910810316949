import React, {FC, PropsWithChildren, ReactNode, useCallback} from 'react';
import block from 'bem-cn-lite';
import {
    SortEnd,
    SortEvent,
    SortableContainer,
    SortableElement,
    SortableHandle,
} from 'react-sortable-hoc';

import './JupyterGrid.scss';
import {NotebookPageQa} from '../../../../../shared/qa';

const b = block('jupyter-grid');

// eslint-disable-next-line new-cap
const DragHandle = SortableHandle(() => <span className={b('drag-handle')}>:::</span>);

// eslint-disable-next-line new-cap
const SortableItem = SortableElement<PropsWithChildren<{}>>(
    (props: {children: ReactNode | undefined}) => {
        return (
            <li className={b('grid-item')}>
                <DragHandle />
                {props.children}
            </li>
        );
    },
);

// eslint-disable-next-line new-cap
const SortableWrapper = SortableContainer<PropsWithChildren<{}>>(
    (props: {children: ReactNode | undefined}) => {
        return <ul className={b('grid')}>{props.children}</ul>;
    },
);

interface JupyterGridProps {
    cellIds: string[];
    handleSortEnd: (sort: SortEnd, event: SortEvent) => void;
    renderCell: (props: {index: number}) => ReactNode;
    renderSeparator: (props: {index: number}) => ReactNode;
    renderPlaceholder: (props: {index: number}) => ReactNode;
    handleKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void;
}

export const JupyterGrid: FC<JupyterGridProps> = (props) => {
    const handleSortStart = useCallback(() => {
        document.body.classList.add('grabbing');
    }, []);

    const handleSortEnd = useCallback(
        (sort: SortEnd, event: SortEvent) => {
            props.handleSortEnd(sort, event);
            document.body.classList.remove('grabbing');
        },
        [props.handleSortEnd],
    );

    return (
        <div
            className={b()}
            tabIndex={-1}
            onKeyDown={props.handleKeyDown}
            data-qa={NotebookPageQa.ContentGrid}
        >
            <SortableWrapper useDragHandle onSortStart={handleSortStart} onSortEnd={handleSortEnd}>
                {props.cellIds.map((cellId, index) => (
                    <React.Fragment key={cellId}>
                        <li className={b('grid-separator')}>
                            {props.renderSeparator({
                                index: index - 1,
                            })}
                        </li>
                        <SortableItem index={index}>
                            {props.renderCell({
                                index,
                            })}
                        </SortableItem>
                    </React.Fragment>
                ))}
                <div className={b('placeholder')}>
                    {props.renderPlaceholder({
                        index: props.cellIds.length ? props.cellIds.length - 1 : 0,
                    })}
                </div>
            </SortableWrapper>
        </div>
    );
};
