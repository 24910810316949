import {combineSlices} from '@reduxjs/toolkit';
import {modalCreateNotebookSlice} from './modal-create-notebook';
import {modalCreateJupytSlice} from './modal-create-jupyt';
import {modalSaveAsNotebookSlice} from './modal-copy-notebook';

export const modals = combineSlices({
    modalCreateNotebook: modalCreateNotebookSlice.reducer,
    modalCreateJupyt: modalCreateJupytSlice.reducer,
    modalSaveAsNotebook: modalSaveAsNotebookSlice.reducer,
});
