import * as React from 'react';
import {useJupyterGrid} from './useJupyterGrid';
import {JupyterGrid} from '../../components/JupyterGrid/JupyterGrid';
import {JupyterCellContainer} from '../JupyterCellContainer/JupyterCellContainer';
import {AddJupyterCellContainer} from '../AddJupyterCellContainer/AddJupyterCellContainer';
import {JupyterCellPlaceholderContainer} from '../JupyterCellPlaceholderContainer/JupyterCellPlaceholderContainer';

export const JupyterGridContainer = () => {
    const jupyterGridProps = useJupyterGrid();

    return (
        <JupyterGrid
            cellIds={jupyterGridProps.cellIds}
            handleSortEnd={jupyterGridProps.handleSortEnd}
            handleKeyDown={jupyterGridProps.handleGridKeyDown}
            renderSeparator={({index}) => <AddJupyterCellContainer addIndex={index} />}
            renderPlaceholder={({index}) => (
                <JupyterCellPlaceholderContainer
                    visible={!jupyterGridProps.cellIds.length}
                    addIndex={index}
                />
            )}
            renderCell={({index}) => <JupyterCellContainer cellIndex={index} />}
        />
    );
};
