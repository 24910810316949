import {useCallback} from 'react';
import isEqual from 'lodash/isEqual';
import {SortEndHandler} from 'react-sortable-hoc';
import {useDispatch, useSelector} from 'react-redux';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';
import {selectNotebookCellIds} from '../../store/selectors/notebook';
import {KeyEnum} from '../../types';

export const useJupyterGrid = () => {
    const dispatch = useDispatch();

    const cellIds = useSelector(selectNotebookCellIds, (prevState, newState) =>
        isEqual(prevState, newState),
    );

    const handleSortEnd: SortEndHandler = useCallback((sort) => {
        dispatch(
            notebookSlice.actions.changeCellPosition({
                oldIndex: sort.oldIndex,
                newIndex: sort.newIndex,
            }),
        );
    }, []);

    const handleGridKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if ([KeyEnum.UP, KeyEnum.DOWN].includes(event.key as KeyEnum)) {
            if (event.key === KeyEnum.UP) {
                dispatch(notebookSlice.actions.upFromCurrentCell());
            }

            if (event.key === KeyEnum.DOWN) {
                dispatch(notebookSlice.actions.downFromCurrentCell());
            }

            event.preventDefault();
        }
    };

    return {
        cellIds,
        handleGridKeyDown,
        handleSortEnd,
    };
};
