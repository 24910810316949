import {createSlice} from '@reduxjs/toolkit';

type ModalCreateDocumentInitialState = {
    visible: boolean;
};

const initialState: ModalCreateDocumentInitialState = {
    visible: false,
};

export const modalCreateDocumentSlice = createSlice({
    name: 'common.modal.create-document',
    initialState,
    reducers: {
        open: (state) => {
            state.visible = true;
        },
        close: (state) => {
            state.visible = false;
        },
    },
});
