import * as React from 'react';
import {useSelector} from 'react-redux';
import {getEditJsonYsonSettings} from '@ytsaurus-ui-platform/src/ui/store/selectors/thor/unipika';
import {UnipikaSettings} from '@ytsaurus-ui-platform/src/ui/components/Yson/StructuredYson/StructuredYsonTypes';

import {
    getJupytOperationOptions,
    getJupytOperationSpeclet,
} from '../../store/selectors/jupytOperation';
import {jupytOptionsEdit, jupytOptionsLoad, jupytSpecletLoad} from '../../store/actions/jupyt';
import {useNebiusDispatch} from '../../../../store/nebius-dispatch';
import {JupytPageOperationSpeclet} from '../../components/JupytOperationPage/JupytPageOperationTabs/JupytPageOperationSpeclet';
import {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

const useSpecletData = ({alias}: {alias: string}) => {
    const dispatch = useNebiusDispatch();

    React.useEffect(() => {
        dispatch(jupytSpecletLoad(alias)).then(() => {
            dispatch(jupytOptionsLoad(alias));
        });
    }, [alias]);

    const speclet = useSelector(getJupytOperationSpeclet);
    const options = useSelector(getJupytOperationOptions);
    const unipikaSettings = useSelector(getEditJsonYsonSettings) as UnipikaSettings;

    const onEditOptions = (
        alias: string,
        options: Required<JupytListResponseItem>['$attributes'],
    ) => {
        return dispatch(
            jupytOptionsEdit({
                alias,
                options,
            }),
        );
    };

    return {
        speclet,
        options,
        unipikaSettings,
        onEditOptions,
    };
};

export const JupytPageOperationSpecletContainer = ({alias}: {alias: string}) => {
    const {speclet, unipikaSettings, options, onEditOptions} = useSpecletData({
        alias,
    });

    return (
        <JupytPageOperationSpeclet
            alias={alias}
            options={options}
            speclet={speclet}
            onEditOptions={onEditOptions}
            unipikaSettings={unipikaSettings}
        />
    );
};
