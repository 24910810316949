import {createNebiusAsyncThunk} from '../../../../store/nebius-async-thunk';
import {YTApiId, ytApiV4Id} from '@ytsaurus-ui-platform/src/ui/rum/rum-wrap-api';
import {wrapApiPromiseByToaster} from '@ytsaurus-ui-platform/src/ui/utils/utils';
import Link from '@ytsaurus-ui-platform/src/ui/components/Link/Link';
import {genNavigationUrl} from '@ytsaurus-ui-platform/src/ui/utils/navigation/navigation';
import React from 'react';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';

const CREATE_DOCUMENT_NODE_ACTION = 'navigation.modal.create-document';

export const createDocument = createNebiusAsyncThunk<void, {path: string}>(
    CREATE_DOCUMENT_NODE_ACTION,
    async ({path}, thunkAPI) => {
        const cluster = getCluster(thunkAPI.getState());
        return wrapApiPromiseByToaster(
            ytApiV4Id.create(YTApiId.navigationSaveDocument, {
                parameters: {path, type: 'document'},
            }),
            {
                toasterName: path,
                timeout: 10000,
                successTitle: 'Document created',
                errorTitle: 'Document creation failure',
                successContent: () => {
                    return (
                        <React.Fragment>
                            Document <Link url={genNavigationUrl({cluster, path})}>{path}</Link>{' '}
                            successfully created
                        </React.Fragment>
                    );
                },
                errorContent: (error) => {
                    return `[code ${error.code}] ${error.message}`;
                },
            },
        );
    },
);
