import React from 'react';
import type {PluginProps} from '@nebius-ui/spd/src/types/configure';
import {NebiusMarkdownContainer} from '../../../../containers/NebiusMarkdownContainer/NebiusMarkdownContainer';
import block from 'bem-cn-lite';

import './MonitoringMarkdown.scss';

const b = block('monitoring-markdown');

type MonitoringMarkdownProps = PluginProps;

export const MonitoringMarkdown = (props: MonitoringMarkdownProps) => {
    const {panel} = props;

    const options = React.useMemo(() => {
        return {
            allowHTML: true,
            disableLiquid: true,
            extendedSanitizeOptions: {
                allowedTags: ['img'],
                allowedAttributes: {
                    img: ['src'],
                },
                allowedSchemes: ['data'],
            },
        };
    }, []);

    return (
        <NebiusMarkdownContainer
            className={b()}
            text={panel.options?.content ?? ''}
            options={options}
        />
    );
};
