import React from 'react';

import block from 'bem-cn-lite';
import {Flex, Text} from '@gravity-ui/uikit';
import type {JupytServerHealthType, JupytServerStateType} from '../../../api/jupyt';

import './JupytSelectOptions.scss';
import {JupytStateIcon} from '../JupytStateIcon/JupytStateIcon';

const b = block('jupyt-select-option');

type JupytSelectOptionProps = {
    value: string;
    state: JupytServerStateType;
    health: JupytServerHealthType;
};

export const JupytSelectOption: React.FC<JupytSelectOptionProps> = (
    props: JupytSelectOptionProps,
) => {
    const {value, state} = props;

    return (
        <Flex gap={5} className={b()}>
            <Flex alignItems="center" justifyItems="center">
                <JupytStateIcon state={state} />
            </Flex>
            <Flex direction="column" className={b('content')}>
                <Flex>
                    <Text variant="body-1" ellipsis={true}>
                        {value}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};
