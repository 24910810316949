import React from 'react';
import {Icon} from '@gravity-ui/uikit';
import {Plus} from '@gravity-ui/icons';
import block from 'bem-cn-lite';
import {NotebookPageQa} from '../../../../../shared/qa';

import './AddJupyterCell.scss';

const b = block('add-jupyter-cell');

type AddJupyterCellProps = {
    visible?: boolean;
    onClick: () => void;
};

export const AddJupyterCell = ({visible, onClick}: AddJupyterCellProps) => {
    return (
        <div data-qa={NotebookPageQa.AddCellSeparator} className={b({visible})} onClick={onClick}>
            <div className={b('content')}>
                <div className={b('separator')}></div>
                <div className={b('add-cta')}>
                    <Icon data={Plus} size={10} />
                </div>
                <div className={b('separator')}></div>
            </div>
        </div>
    );
};
