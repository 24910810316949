export class Deferred<T = unknown> {
    resolve!: (v: T | Promise<T>) => void;
    reject!: () => void;
    promise: Promise<T>;

    constructor() {
        const promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.promise = promise;
    }
}
