import {createAsyncThunk} from '@reduxjs/toolkit';
import type {NebiusRootState} from './reducers';
import type {NebiusThunkDispatch} from './nebius-dispatch';

export type NebiusAsyncThunkConfig = {
    state: NebiusRootState;
    dispatch: NebiusThunkDispatch;
};

export const createNebiusAsyncThunk = createAsyncThunk.withTypes<NebiusAsyncThunkConfig>();
