import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import type {NebiusRootState} from './reducers';
import {useDispatch} from 'react-redux';

export type NebiusThunkDispatch<T extends AnyAction = AnyAction> = ThunkDispatch<
    NebiusRootState,
    any,
    T
>;

type ActionWithoutMatch<T> = Omit<T, 'match'>;

export type SliceActionType<T extends {[key: string]: any}> = {
    [K in keyof T]: ActionWithoutMatch<T[K]>;
}[keyof T];

export type NebiusSliceThunkDispatch<T extends AnyAction> = ThunkDispatch<NebiusRootState, any, T>;

export const useNebiusDispatch = <T extends AnyAction>() => useDispatch<NebiusThunkDispatch<T>>();
