import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

export const mapJupyterKind: Map<string, number> = new Map<string, number>([
    ['method', monaco.languages.CompletionItemKind.Method],
    ['function', monaco.languages.CompletionItemKind.Function],
    ['constructor', monaco.languages.CompletionItemKind.Constructor],
    ['field', monaco.languages.CompletionItemKind.Field],
    ['variable', monaco.languages.CompletionItemKind.Variable],
    ['class', monaco.languages.CompletionItemKind.Class],
    ['struct', monaco.languages.CompletionItemKind.Struct],
    ['interface', monaco.languages.CompletionItemKind.Interface],
    ['module', monaco.languages.CompletionItemKind.Module],
    ['property', monaco.languages.CompletionItemKind.Property],
    ['event', monaco.languages.CompletionItemKind.Event],
    ['operator', monaco.languages.CompletionItemKind.Operator],
    ['unit', monaco.languages.CompletionItemKind.Unit],
    ['value', monaco.languages.CompletionItemKind.Value],
    ['constant', monaco.languages.CompletionItemKind.Constant],
    ['enum', monaco.languages.CompletionItemKind.Enum],
    ['enumMember', monaco.languages.CompletionItemKind.EnumMember],
    ['keyword', monaco.languages.CompletionItemKind.Keyword],
    ['text', monaco.languages.CompletionItemKind.Text],
    ['color', monaco.languages.CompletionItemKind.Color],
    ['file', monaco.languages.CompletionItemKind.File],
    ['reference', monaco.languages.CompletionItemKind.Reference],
    ['customcolor', monaco.languages.CompletionItemKind.Customcolor],
    ['folder', monaco.languages.CompletionItemKind.Folder],
    ['typeParameter', monaco.languages.CompletionItemKind.TypeParameter],
    ['snippet', monaco.languages.CompletionItemKind.Snippet],
    ['<unknown>', monaco.languages.CompletionItemKind.Field],
]);

// https://github.com/microsoft/vscode-jupyter/blob/e01d69cb5f483945eadd2ca0e1f71fb1b2e4c8d1/src/standalone/intellisense/helpers.ts#L4
export function generateSortString(index: number) {
    // If its 0, then use AA, if 25, then use ZZ
    // This will give us the ability to sort first 700 items (thats more than enough).
    // To keep things fast we'll only sort the first 300.
    if (index >= 300) {
        return 'ZZZZZZZ';
    }
    if (index <= 25) {
        return `A${String.fromCharCode(65 + index)}`;
    }
    const firstChar = String.fromCharCode(65 + Math.ceil(index / 25));
    const secondChar = String.fromCharCode(65 + (index % 25));
    return `${firstChar}${secondChar}`;
}

export const prepareJupyterSuggestions = (
    matches: string[],
    range: monaco.IRange,
    experimentMatches: any[],
): monaco.languages.CompletionList['suggestions'] => {
    return matches.map<monaco.languages.CompletionItem>((match, index) => {
        const item = experimentMatches[index];
        const type = item?.type
            ? mapJupyterKind.get(item.type) ?? monaco.languages.CompletionItemKind.Field
            : monaco.languages.CompletionItemKind.Field;
        // https://github.com/microsoft/vscode-jupyter/blob/e01d69cb5f483945eadd2ca0e1f71fb1b2e4c8d1/src/standalone/intellisense/kernelCompletionProvider.ts#L248
        let sortText = generateSortString(index);
        if (match.startsWith('%') || match.startsWith('!')) {
            // Update magics to have a much lower sort order than other strings.
            // Also change things that start with our current word to eliminate the
            // extra long label.
            sortText = `ZZZ${sortText}`;
        }

        const label = match.replace('%%', '');

        return {
            label: label,
            insertText: label,
            kind: type,
            sortText,
            range,
        };
    });
};
