import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {JupytListResponseItem} from 'features/Jupyter/api/jupyt';

export type JupytDefaultsType = {
    base_kernel_image: string;
    default_kernel_image: string;
};

type JupytState = {
    jupytDefaults: JupytDefaultsType;
    currentJupytAlias: string;
    operations: JupytListResponseItem[];
};

const initialState: JupytState = {
    jupytDefaults: {
        default_kernel_image:
            'cr.ai.nebius.cloud/crnf2coti090683j5ssi/kernels/default:2024-07-30-16-39-56-14c3d05e',
        base_kernel_image: '',
    },
    currentJupytAlias: '',
    operations: [],
};

export const jupytSlice = createSlice({
    name: 'jupyter.jupyt',
    initialState,
    reducers: {
        setJupytList: (state, action: PayloadAction<{operations: JupytListResponseItem[]}>) => {
            state.operations = action.payload.operations;
        },
        setCurrentJupytAlias(state, action: PayloadAction<{alias: string}>) {
            state.currentJupytAlias = action.payload.alias;
        },
        setJupytDefaults(state, action: PayloadAction<{jupytDefaults: JupytDefaultsType}>) {
            state.jupytDefaults = action.payload.jupytDefaults;
        },
        clearJupytState: () => initialState,
    },
});
