import {getPool} from '@ytsaurus-ui-platform/src/ui/store/selectors/scheduling/scheduling';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {SchedulerPoolDashboardConfigVariables} from '../../configs/SchedulerPoolDashboardConfig';
import {ServiceDashboard} from '../../components/ServiceDashboard/ServiceDashboard';
import {getCluster} from '@ytsaurus-ui-platform/src/ui/store/selectors/global';
import {schedulerPoolDashboardConfig} from '../../../../../shared/constants/monitoring/scheduler-pool-dashboard-config';

type SchedulerPoolDashboardContainerProps = {};

export const SchedulerPoolDashboardContainer: React.FC<
    SchedulerPoolDashboardContainerProps
> = () => {
    const pool = useSelector(getPool);
    const cluster = useSelector(getCluster);

    const config = useMemo(() => {
        const variables: SchedulerPoolDashboardConfigVariables = {
            pool: [pool],
            __rate_interval: ['30s'],
            cluster: [cluster],
        };

        return {
            dashboard: schedulerPoolDashboardConfig,
            variables,
        };
    }, [pool, cluster]);

    return (
        <ServiceDashboard key={pool} dashboard={config.dashboard} variables={config.variables} />
    );
};
