import React from 'react';
import {UIFactory} from '@ytsaurus-ui-platform/src/ui/UIFactory';
import type {AppNavigationProps} from '@ytsaurus-ui-platform/src/ui/containers/AppNavigation/AppNavigationPageLayout';
import {NebiusSubjectCard} from '../components/NebiusSubjectCard/NebiusSubjectCard';
import {nebiusAppReducers} from '../store/reducers';
import {
    JUPYTER_NAVIGATION_MAP_NODE_SETTINGS,
    JUPYTER_NOTEBOOK_NAVIGATION_TAB,
    JUPYT_OPERATIONS_PAGE,
} from 'features/Jupyter';
import {monitoring} from 'features/Monitoring';
import {AppNavigationPageLayout} from '../features/Navigation';
import type {DropdownMenuItem} from '@gravity-ui/uikit';
import {NotebookSaveDialog} from '../features/Jupyter/containers/Dialogs/NotebookSaveDialog/NotebookSaveDialog';
import {getNebiusMapNodeToolbarCreateActions} from '../pages/navigation/MapNode/utils/dropdown-actions';
import {isEnvTrue} from '../../shared/utils/env';
import {getNebiusConfigData, isProductionEnvironment} from '../config/ui-config';
import {DocumentCreateDialog} from '../features/Navigation/containers/Dialogs/DocumentCreateDialogs';

const productionOverrides: Partial<UIFactory> = {
    renderSubjectCard(props) {
        return <NebiusSubjectCard {...props} />;
    },

    getExtraReducersAndUrlMapping() {
        return {
            reducers: nebiusAppReducers,
        };
    },
    renderAppNavigation(props: AppNavigationProps): React.ReactElement {
        return <AppNavigationPageLayout {...props} />;
    },

    getMapNodeExtraCreateActions(baseActions: Array<DropdownMenuItem>): {
        menuItems: Array<DropdownMenuItem>;
        renderModals: () => React.ReactNode;
    } {
        return {
            menuItems: getNebiusMapNodeToolbarCreateActions(baseActions),
            renderModals: () => (
                <>
                    <NotebookSaveDialog />
                    <DocumentCreateDialog />
                </>
            ),
        };
    },

    getNavigationMapNodeSettings() {
        return JUPYTER_NAVIGATION_MAP_NODE_SETTINGS;
    },
};

let developmentOverrides: Partial<UIFactory> = {};

if (!isProductionEnvironment()) {
    developmentOverrides = {
        ...developmentOverrides,
        ...monitoring,
    };
}

if (isEnvTrue(getNebiusConfigData().env.SHOW_POOL_DASHBOARD)) {
    developmentOverrides = {
        ...developmentOverrides,
        getSchedulingExtraTabs: monitoring.getSchedulingExtraTabs,
    };
}

if (isEnvTrue(getNebiusConfigData().env.SHOW_JUPYT_INTERFACE)) {
    developmentOverrides = {
        ...developmentOverrides,
        getNavigationExtraTabs() {
            return [JUPYTER_NOTEBOOK_NAVIGATION_TAB];
        },
        getExtaClusterPages() {
            return [JUPYT_OPERATIONS_PAGE];
        },
    };
}

export const factoryOverrides = {
    ...productionOverrides,
    ...developmentOverrides,
} as Partial<UIFactory>;
