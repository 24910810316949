import React from 'react';
import {SecretsList} from 'features/Jupyter/components/SecretsList/SecretsList';

type JupyterSecretsProps = {
    value: {name: string; value: string}[];
    onChange: (value: {name: string; value: string}[]) => void;
};

export function JupytSecrets({value, onChange}: JupyterSecretsProps) {
    return <SecretsList value={value} onUpdate={onChange} />;
}

JupytSecrets.getDefaultValue = () => {
    return {};
};

JupytSecrets.renderLabel = (field: {caption: string}) => {
    if (field.caption) {
        return field.caption;
    }

    return null;
};
