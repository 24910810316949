// Source 1: https://github.com/curvenote/ansi-to-react/blob/main/src/index.ts
// Source 2: https://github.com/microsoft/vscode/blob/9f709d170b06e991502153f281ec3c012add2e42/src/vs/workbench/contrib/debug/browser/linkDetector.ts#L54
import React from 'react';
import type {AnserJsonEntry} from 'anser';
import Anser from 'anser';
import block from 'bem-cn-lite';

import './JupyterAnsi.scss';
import {NebiusYT} from 'config/ui-config';

const b = block('jupyter-ansi');

function escapeCarriageReturn(txt: string) {
    if (!txt) return '';
    if (!/\r/.test(txt)) return txt;
    txt = txt.replace(/\r+\n/gm, '\n'); // \r followed by \n --> newline
    while (/\r./.test(txt)) {
        txt = txt.replace(/^([^\r\n]*)\r+([^\r\n]+)/gm, function (_, base, insert) {
            return insert + base.slice(insert.length);
        });
    }
    return txt;
}

function ansiToJSON(input: string): AnserJsonEntry[] {
    input = escapeCarriageReturn(fixBackspace(input));
    return Anser.ansiToJson(input, {
        json: true,
        remove_empty: true,
        use_classes: true,
    });
}

function createClass(bundle: AnserJsonEntry): string | null {
    let classNames = '';

    if (bundle.bg) {
        classNames += `${bundle.bg}-bg `;
    }
    if (bundle.fg) {
        classNames += `${bundle.fg}-fg `;
    }
    if (bundle.decoration) {
        classNames += `ansi-${bundle.decoration} `;
    }

    if (classNames === '') {
        return null;
    }

    classNames = classNames.substring(0, classNames.length - 1);
    return classNames;
}

/**
 * Converts an Anser bundle into a React Node.
 * @param bundle Anser output.
 * @param key
 */

function convertBundleIntoReact(bundle: AnserJsonEntry, key: number): JSX.Element {
    const className = createClass(bundle);

    const content: React.ReactNode[] = [];

    for (const part of detectLinks(bundle.content)) {
        switch (part.kind) {
            case 'text': {
                content.push(part.value);
                break;
            }
            case 'web': {
                const href = part.value;
                content.push(
                    React.createElement(
                        'a',
                        {
                            href,
                            target: '_blank',
                        },
                        `${href}`,
                    ),
                );
                break;
            }
            case 'path': {
                const path = part.value;
                const href = `${location.protocol}//${location.host}/${NebiusYT.cluster}/navigation?path=${path}`;
                content.push(
                    React.createElement(
                        'a',
                        {
                            href,
                            target: '_blank',
                        },
                        `${path}`,
                    ),
                );
                break;
            }
        }
    }

    return React.createElement('span', {key, className}, content);
}

type LinkKind = 'web' | 'path' | 'text';

type LinkPart = {
    kind: LinkKind;
    value: string;
    captures: string[];
};

const MAX_LENGTH = 2000;

const CONTROL_CODES = '\\u0000-\\u0020\\u007f-\\u009f';

const WEB_LINK_REGEX = new RegExp(
    '(?:[a-zA-Z][a-zA-Z0-9+.-]{2,}:\\/\\/|data:|www\\.)[^\\s' +
        CONTROL_CODES +
        '"]{2,}[^\\s' +
        CONTROL_CODES +
        '"\')}\\],:;.!?]',
    'ug',
);

const POSIX_PATH = /((?:~|\.)?(?:\/[\w.-]*)+)/;

const CYPRESS_PATH = new RegExp(`/${POSIX_PATH.source}`);

const PATH_LINK_REGEX = new RegExp(`${CYPRESS_PATH.source}`, 'g');

function detectLinks(text: string): LinkPart[] {
    if (text.length > MAX_LENGTH) {
        return [{kind: 'text', value: text, captures: []}];
    }

    const regexes: RegExp[] = [WEB_LINK_REGEX, PATH_LINK_REGEX];
    const kinds: LinkKind[] = ['web', 'path'];
    const result: LinkPart[] = [];

    const splitOne = (text: string, regexIndex: number) => {
        if (regexIndex >= regexes.length) {
            result.push({value: text, kind: 'text', captures: []});
            return;
        }
        const regex = regexes[regexIndex];
        let currentIndex = 0;
        let match;
        regex.lastIndex = 0;
        while ((match = regex.exec(text)) !== null) {
            const stringBeforeMatch = text.substring(currentIndex, match.index);
            if (stringBeforeMatch) {
                splitOne(stringBeforeMatch, regexIndex + 1);
            }
            const value = match[0];
            result.push({
                value: value,
                kind: kinds[regexIndex],
                captures: match.slice(1),
            });
            currentIndex = match.index + value.length;
        }
        const stringAfterMatches = text.substring(currentIndex);
        if (stringAfterMatches) {
            splitOne(stringAfterMatches, regexIndex + 1);
        }
    };

    splitOne(text, 0);
    return result;
}

declare interface Props {
    id?: string;
    children?: string;
    className?: string;
}

function Ansi(props: Props): JSX.Element {
    const {className, children} = props;
    return React.createElement(
        'code',
        {className},
        ansiToJSON(children ?? '').map((item, index) => convertBundleIntoReact(item, index)),
    );
}

// This is copied from the Jupyter Classic source code
// notebook/static/base/js/utils.js to handle \b in a way
// that is **compatible with Jupyter classic**.   One can
// argue that this behavior is questionable:
//   https://stackoverflow.com/questions/55440152/multiple-b-doesnt-work-as-expected-in-jupyter#
function fixBackspace(txt: string) {
    let tmp = txt;
    do {
        txt = tmp;
        // Cancel out anything-but-newline followed by backspace
        // eslint-disable-next-line no-control-regex
        tmp = txt.replace(/[^\n]\x08/gm, '');
    } while (tmp.length < txt.length);
    return txt;
}

type JupyterAnsiProps = {children?: string};

export const JupyterAnsi: React.FC<JupyterAnsiProps> = ({children}) => {
    return <Ansi className={b()}>{children}</Ansi>;
};
