import type {JupytSecretType, JupytSecretsMapType} from '../../types/jupyt/secrets';

export const mapJupytSecretsToList = (secrets: JupytSecretsMapType | null): JupytSecretType[] => {
    return Object.entries(secrets || {}).map(([name, value]) => ({
        name: String(name),
        value: String(value),
    }));
};

export const mapListToJupytSecrets = (list: JupytSecretType[]) =>
    list.reduce((acc: JupytSecretsMapType, {name, value}) => {
        acc[name] = value;
        return acc;
    }, {});
