import React from 'react';
import type {Output} from '@diplodoc/transform';
import {nebiusTransformMarkdown} from '../../api/markdown';
import type {MarkdownOptions} from '../../../shared/types/common/markdown';
import {NebiusMarkdown} from '../../components/NebiusMarkdown/NebiusMarkdown';
import {Flex, Loader} from '@gravity-ui/uikit';

type NebiusMarkdownProps = {
    text: string;
    options: MarkdownOptions;
    className?: string;
};

const useMarkdown = (
    text: NebiusMarkdownProps['text'],
    options: NebiusMarkdownProps['options'],
) => {
    const [result, setResult] = React.useState<Output>(() => ({
        result: {html: '', headings: []},
        logs: {error: [], disabled: [], warn: [], info: []},
    }));
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function transform() {
            setLoading(true);
            try {
                const data = await nebiusTransformMarkdown({text, options});

                setResult(data);
            } catch (e) {
            } finally {
                setLoading(false);
            }
        }
        transform();
    }, [text, options]);

    return {...result, loading};
};

const NebiusMarkdownContainerComponent: React.FC<NebiusMarkdownProps> = (
    props: NebiusMarkdownProps,
) => {
    const {className, options, text} = props;

    const {result, loading} = useMarkdown(text, options);

    return loading ? (
        <Flex alignItems="center" width="100%" height="100%" justifyContent="center">
            <Loader size="s" />
        </Flex>
    ) : (
        <NebiusMarkdown html={result.html} className={className} />
    );
};

export const NebiusMarkdownContainer = React.memo(NebiusMarkdownContainerComponent);
