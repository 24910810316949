import React, {useCallback} from 'react';
import {AddJupyterCell} from 'features/Jupyter/components/AddJupyterCell/AddJupyterCell';
import {useNebiusDispatch} from 'store/nebius-dispatch';
import {notebookSlice} from 'features/Jupyter/store/slices/notebook';

type AddJupyterCellConainerProps = {addIndex: number; visible?: boolean};

export const AddJupyterCellContainer = ({addIndex, visible}: AddJupyterCellConainerProps) => {
    const dispatch = useNebiusDispatch();

    const onClick = useCallback(() => {
        dispatch(notebookSlice.actions.addCellAfter({currentIndex: addIndex, type: 'code'}));
    }, [addIndex]);

    return <AddJupyterCell visible={visible} onClick={onClick} />;
};
