import {createSlice} from '@reduxjs/toolkit';

type ModalCreateJupytInitialState = {
    visible: boolean;
    isCanceled: boolean;
};

const initialState: ModalCreateJupytInitialState = {
    visible: false,
    isCanceled: false,
};

export const modalCreateJupytSlice = createSlice({
    name: 'jupyter.modal.create-jupyt',
    initialState,
    reducers: {
        open: (state) => {
            state.visible = true;
            state.isCanceled = false;
        },
        cancel: (state) => {
            state.visible = false;
            state.isCanceled = true;
        },
        close: (state) => {
            state.visible = false;
        },
    },
});
