import type {NebiusRootState} from '../../../../store/reducers';

export const getJupytOperation = (state: NebiusRootState) =>
    state.nebius.jupyter.jupytOperation.operation;

export const getJupytOperationSpeclet = (state: NebiusRootState) =>
    state.nebius.jupyter.jupytOperation.speclet;

export const getJupytOperationOptions = (state: NebiusRootState) =>
    state.nebius.jupyter.jupytOperation.options;
