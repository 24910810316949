import {KernelManager, ServerConnection, Session} from '@jupyterlab/services';
import {NebiusLogger} from '../../../../utils/logging';
import {Deferred} from '../../../../utils/deferred';
import {
    createSession,
    getOptionalSessionManager,
    getSessionManager,
    resetSessionManager,
} from './sessions';
import {getJupyterBaseUrl} from '../../../../../shared/utils/jupyter/jupyter-api';
import {delay} from '@ytsaurus-ui-platform/src/ui/packages/delays';

export class JupyterApi {
    private static kernelManager: KernelManager | null = null;
    private static sessionConnection: Deferred<Session.ISessionConnection> =
        new Deferred<Session.ISessionConnection>();

    static async setupKernelConnection(args: {
        alias: string;
        cluster: string;
        path: string;
        notebookId: string;
    }) {
        const {alias, cluster, notebookId, path} = args;
        if (!this.kernelManager) {
            this.kernelManager = await this.createKernelManager(alias, cluster);
        }

        NebiusLogger.log('Starting kernel connection.');

        const sessionManager = getSessionManager(this.kernelManager, {alias, cluster});
        const result = await createSession(sessionManager, {path, notebookId});

        NebiusLogger.log('Session connection created');
        NebiusLogger.log('SessionConnection ID: ', result.id);
        NebiusLogger.log('KernelConnection ID: ', result.kernel?.id);

        if (!result.kernel) {
            NebiusLogger.log("KernelConnection doesn't exist, something went wrong");
            throw new Error("KernelConnection doesn't exist!");
        }

        NebiusLogger.log('KernelConnection successfully started');

        this.sessionConnection.resolve(result);
    }

    static disposeConnection() {
        const sessionManager = getOptionalSessionManager();

        if (!sessionManager) {
            NebiusLogger.log('Session manager is not initialized...');
            NebiusLogger.log('Sessions do not exist');
            return;
        }

        sessionManager.dispose();
        this.kernelManager?.dispose();

        this.sessionConnection = new Deferred<Session.ISessionConnection>();
        this.kernelManager = null;
        resetSessionManager();
    }

    static getKernelConnection() {
        return this.sessionConnection.promise.then((connection) => connection.kernel!);
    }

    static getSessionConnection() {
        return this.sessionConnection.promise;
    }

    static async getCompletion(
        source: string,
        cursor: number,
        {alias, cluster}: {alias: string; cluster: string},
    ) {
        if (!this.kernelManager) {
            this.kernelManager = await this.createKernelManager(alias, cluster);
        }

        const kernelConnection = await this.getKernelConnection();

        return kernelConnection.requestComplete({
            code: source,
            cursor_pos: cursor,
        });
    }

    private static async createKernelManager(alias: string, cluster: string) {
        NebiusLogger.log('Trying to create KernelManager...');

        const serverSettings = ServerConnection.makeSettings({
            baseUrl: getJupyterBaseUrl({cluster, alias}),
        });

        const MAX_CONNECT_ATTEMPTS = 5;

        for (let connectAttempt = 0; connectAttempt < MAX_CONNECT_ATTEMPTS; connectAttempt++) {
            NebiusLogger.log(`Trying to get api/status... ${connectAttempt + 1}`);

            const kernel = await ServerConnection.makeRequest(
                `${serverSettings.baseUrl}/api/status`,
                {},
                serverSettings,
            );

            if (kernel.ok) {
                NebiusLogger.log(`Status is ok!`);

                return new KernelManager({
                    serverSettings,
                });
            }

            await delay(5 * 1000);
        }

        NebiusLogger.log(`Didn't get api/status for ${MAX_CONNECT_ATTEMPTS} times`);

        throw new Error(`Can't connect to kernel`);
    }
}
