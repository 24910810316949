import React from 'react';

import format from '@ytsaurus-ui-platform/src/ui/common/hammer/format';
import Label, {LabelTheme} from '@ytsaurus-ui-platform/src/ui/components/Label/Label';

const THEME_MAP = {
    good: 'success',
    failed: 'danger',
    active: 'success',
    pending: 'info',
    untracked: 'warning',
    inactive: 'misc',
};

export function JupytState({state}: {state?: keyof typeof THEME_MAP}) {
    return !state ? (
        format.NO_VALUE
    ) : (
        <Label text={state} theme={String(THEME_MAP[state]) as LabelTheme} hideTitle capitalize />
    );
}
