import React from 'react';
import {ServiceProviderDashboard} from '@nebius-ui/spd';
import type {Dashboard, Variables} from '@nebius-ui/spd';
import {useThemeValue} from '@gravity-ui/uikit';
import {monitoringSdk} from '../../api/monitoring-sdk';

export interface ServiceDashboardProps {
    dashboard: Dashboard;
    variables?: Variables;
}

export const ServiceDashboard = ({dashboard, variables}: ServiceDashboardProps) => {
    const theme = useThemeValue() === 'light' ? 'light' : 'dark';
    return (
        <ServiceProviderDashboard
            sdk={monitoringSdk}
            theme={theme}
            keepUrlState
            variables={variables}
            dashboard={dashboard}
        />
    );
};
